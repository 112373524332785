import React, { useRef } from 'react';

import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import Features2 from './Features2';
import Features from './Features';
import SectionHeader from '../../SectionHeader';

import useElementVisible from '../../../../hooks/useElementVisible';
import classes from './styles.module.scss';
import process from '../../../../assets/images/process/process.svg';
import isoIcon from '../../../../assets/images/complience/iso.png';
import gdprIcon from '../../../../assets/images/complience/gdpr.png';
import owaspIcon from '../../../../assets/images/complience/owasp.png';
import pciIcon from '../../../../assets/images/complience/pci.png';
import psd2Icon from '../../../../assets/images/complience/psd2.png';
import fismaIcon from '../../../../assets/images/complience/fisma.png';
import hipaaIcon from '../../../../assets/images/complience/hipaa.png';
import amlIcon from '../../../../assets/images/complience/aml.png';

const complienceIcons = [
  { icon: isoIcon, alt: 'ISO' },
  { icon: gdprIcon, alt: 'GDPR' },
  { icon: owaspIcon, alt: 'OWASP' },
  { icon: pciIcon, alt: 'PCI' },
  { icon: psd2Icon, alt: 'PSD2' },
  { icon: fismaIcon, alt: 'FISMA' },
  { icon: hipaaIcon, alt: 'HIPAA' },
  { icon: amlIcon, alt: 'AML' },
];

export default function Process() {
  const navigate = useNavigate();

  const sectionRef = useRef();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  useElementVisible(
    sectionRef,
    () => navigate('#process', { replace: true }),
    0.25
  );
  return (
    <section className={classes.Process} id="process" ref={sectionRef}>
      <SectionHeader
        leftBackgroundRotation={30}
        rightBackgroundRotation={240}
        rightBackgroundTop={-40}
        title="PROCESS"
        text="We deliver faster with iterative milestone-based software development"
        style={{ marginBottom: isTabletOrMobile ? 75 : 200 }}
      />
      <div className={classes.complienceListContainer}>
        <ul className={classes.complienceList}>
          {complienceIcons.map((icon) => (
            <li key={icon.alt}>
              <img src={icon.icon} className={classes.icon} alt={icon.alt} />
            </li>
          ))}
        </ul>
      </div>
      <Features />
      <div className={classes.processImageContainer}>
        <img src={process} alt="Process" className={classes.process} />
      </div>
      <Features2 />
    </section>
  );
}
