import React from 'react';

import Technoligies from '../../components/MainPage/Sections/Technologies';
import Process from '../../components/MainPage/Sections/Process';
import Services from '../../components/MainPage/Sections/Services';
import Header from '../../components/MainPage/Header';
import Hero from '../../components/MainPage/Sections/Hero';

import classes from './styles.module.scss';
import Clients from '../../components/MainPage/Sections/Clients';
import Contacts from '../../components/MainPage/Sections/Contacts';
import Footer from '../../components/MainPage/Footer';

export default function MainPage() {
  //  useScrollToHashElement();
  return (
    <div className={classes.MainPage}>
      <Header />
      <main className={classes.sections}>
        <Hero />
        <Services />
        <Process />
        <Technoligies />
        <Clients />
        <Contacts />
      </main>
      <Footer />
    </div>
  );
}
