import React from 'react';

import milestoneIcon from '../../../../../assets/images/process/features/milestone.png';
import iterativeIcon from '../../../../../assets/images/process/features/iterative.png';
import testIcon from '../../../../../assets/images/process/features/test.png';
import classes from './styles.module.scss';

const features = [
  {
    title: 'MILESTONE BASED',
    text: (
      <>
        Deliver Value Quickly with <br /> Milestone-Driven Software Development
      </>
    ),
    icon: milestoneIcon,
  },
  {
    title: 'ITERATIVE',
    text: (
      <>
        Iterate and Improve: An Agile
        <br /> Process for Software Success
      </>
    ),
    icon: iterativeIcon,
  },
  {
    title: 'TEST DRIVEN',
    text: (
      <>
        Proactive Quality
        <br /> Assurance is essential
      </>
    ),
    icon: testIcon,
  },
];

export default function Features() {
  return (
    <div className={classes.Features}>
      <ul className={classes.featuresList}>
        {features.map((feature) => (
          <li key={feature.title} className={classes.feature}>
            <div className={classes.imageContainer}>
              <img src={feature.icon} alt={feature.title} />
            </div>
            <div>
              <h3>{feature.title}</h3>
              <p>{feature.text}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
