import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function Background({ right, rotation, rightTop }) {
  return (
    <div
      style={{
        transform: `rotate(${rotation}deg)`,
        top: right ? rightTop : '',
      }}
      className={classNames(classes.Background, {
        [classes.right]: right,
      })}
    />
  );
}
