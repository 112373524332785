import React, { useRef } from 'react';

import axios from 'axios';
import { useMediaQuery } from 'react-responsive';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import 'react-toastify/dist/ReactToastify.css';

import Textarea from '../../../Form/Textarea';
import TextInput from '../../../Form/TextInput';
import SectionHeader from '../../SectionHeader';

import emailIcon from '../../../../assets/images/email-icon.png';
import { API_URL } from '../../../../constants/main';
import useElementVisible from '../../../../hooks/useElementVisible';
import classes from './styles.module.scss';

const validationSchema = yup.object({
  name: yup.string().trim().required('This field is required.'),
  message: yup.string().trim().required('This field is required.'),
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email address.')
    .required('This field is required'),
});

export default function Contacts() {
  const navigate = useNavigate();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const sectionRef = useRef();
  useElementVisible(sectionRef, () => navigate('#contact', { replace: true }));

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        formik.resetForm();
        await axios.post(`${API_URL}/emails`, values);
        toast.success('Email has been sent.');
      } catch (error) {
        console.log(error);
        toast.error('Something went wrong.');
      }
    },
  });

  return (
    <section className={classes.Contacts} id="contact" ref={sectionRef}>
      <div className={classes.headerContainer}>
        <SectionHeader
          leftBackgroundRotation={30}
          rightBackgroundRotation={235}
          rightBackgroundTop={0}
          style={{ marginBottom: isTabletOrMobile ? 75 : 120 }}
          title="Estimate your project now!"
          text={
            <>
              We&apos;ll provide you with the resources and expertise you need
              to turn your idea into a success. <br /> <br /> Together,
              we&apos;ll create something amazing!
            </>
          }
        />
      </div>
      <p className={classes.email}>
        Contact us at
        <img className={classes.emailIcon} src={emailIcon} alt="Email" />{' '}
        <a href="mailto:info@deloki.com">info@deloki.com</a>
      </p>
      <div className={classes.heading}>OR</div>
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <TextInput
          placeholder="Name"
          value={formik.values.name}
          name="name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.name}
          touched={formik.touched.name}
        />
        <TextInput
          placeholder="Email"
          value={formik.values.email}
          name="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.email}
          touched={formik.touched.email}
        />
        <Textarea
          placeholder="Briefly describe your project or idea"
          value={formik.values.message}
          name="message"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.message}
          touched={formik.touched.message}
        />
        <div className={classes.buttonContainer}>
          <button type="submit">Send</button>
        </div>
      </form>
      <ToastContainer />
    </section>
  );
}
