import React from 'react';

import classes from './styles.module.scss';

export default function TextInput({
  type,
  placeholder,
  value,
  onChange,
  name,
  onBlur,
  touched,
  error,
}) {
  return (
    <>
      <input
        type={type || 'text'}
        className={classes.TextInput}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        onBlur={onBlur}
      />
      {touched && error && <div className={classes.error}>{error}</div>}
    </>
  );
}
