import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import MainPage from '../pages/MainPage';

function RoutesCompnent() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default RoutesCompnent;
