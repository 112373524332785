import React from 'react';

import { useMediaQuery } from 'react-responsive';

import classes from './styles.module.scss';
import researchIcon from '../../../../../assets/images/process/features/maintenance.png';
import designIcon from '../../../../../assets/images/process/features/design.png';
import implementationIcon from '../../../../../assets/images/process/features/code.png';

const features = [
  {
    title: 'RESEARCH',
    text: 'We examine business objectives and user requirements, comparing them to similar experiences to recognize trends and user expectations.',
    icon: researchIcon,
    textWidth: 310,
    maxWidth: 430,
  },
  {
    title: 'DESIGN',
    text: 'Great design is not only about looking attractive, but also about providing a functional, intuitive, and enjoyable user experience.',
    icon: designIcon,
    textWidth: 360,
    maxWidth: 530,
  },
  {
    title: 'IMPLEMENTATION',
    text: 'Our code is built to be extensible and customizable, enabling us to quickly and easily tailor it to changing customer requirements.',
    icon: implementationIcon,
    textWidth: 350,
    maxWidth: 420,
  },
];

export default function Features2() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <div className={classes.Features2}>
      <ul className={classes.featuresList}>
        {features.map((feature) => (
          <li
            key={feature.title}
            className={classes.feature}
            style={{ maxWidth: isTabletOrMobile ? '' : feature.maxWidth }}
          >
            <div
              className={classes.header}
              style={{ maxWidth: isTabletOrMobile ? '' : feature.textWidth }}
            >
              <img src={feature.icon} alt={feature.title} />
              <h3>{feature.title}</h3>
            </div>
            <p style={{ maxWidth: isTabletOrMobile ? '' : feature.textWidth }}>
              {feature.text}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
}
