import React, { useEffect } from 'react';

import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import classes from './styles.module.scss';
import Shape from './Shape';

const shapes = [
  {
    type: 'triangle',
    top: 0,
    left: '15%',
    scale: 1,
  },
  {
    type: 'triangle',
    top: 20,
    right: '3%',
    scale: 0.9,
  },
  {
    type: 'circle',
    top: 80,
    left: '5%',
    scale: 0.8,
  },
  {
    type: 'circle',
    top: 100,
    right: '5%',
    scale: 0.7,
  },
  {
    type: 'triangle',
    top: 175,
    left: '12%',
    scale: 0.9,
  },
  {
    type: 'triangle',
    top: 230,
    right: '7%',
    scale: 1,
  },
  {
    type: 'circle',
    top: 280,
    left: '15%',
    scale: 0.6,
  },
  {
    type: 'triangle',
    top: 360,
    right: '17%',
    scale: 1,
  },
  {
    type: 'triangle',
    top: 430,
    left: '5%',
    scale: 1,
  },
  {
    type: 'circle',
    top: 480,
    right: '7%',
    scale: 0.8,
  },
  {
    type: 'circle',
    top: 530,
    right: '30%',
    scale: 0.75,
  },
  {
    type: 'triangle',
    top: 600,
    left: '30%',
    scale: 1,
  },
];

export default function MobileMenu({
  show,
  handleClose,
  navLinks,
  location,
  scrollIntoView,
}) {
  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = 'unset';
    };
  }, [show]);

  return (
    <div
      className={classNames(classes.MobileMenu, {
        [classes.visible]: show,
      })}
    >
      <nav className={classes.mainNav}>
        <ul className={classes.linksList}>
          {navLinks.map((link) => (
            <li key={link.title} className={classes.mainLink}>
              <NavLink
                onClick={() => {
                  scrollIntoView(link.href.slice(1));
                  handleClose();
                }}
                to="/"
                className={() =>
                  location.hash === link.href ? classes.active : undefined
                }
              >
                <span>{link.title}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
      {shapes.map((shape, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Shape key={index} shape={shape} />
      ))}
    </div>
  );
}
