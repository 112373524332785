import React from 'react';

import { Link } from 'react-router-dom';

import classes from './styles.module.scss';

export default function LinkButton({ onClick }) {
  return (
    <Link
      to="#contact"
      onClick={() => {
        const element = document.getElementById('contact');

        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            // block: "end",
            inline: 'nearest',
          });
        }

        onClick?.();
      }}
    >
      <span className={classes.LinkButton}>{`Let's talk`}</span>
    </Link>
  );
}
