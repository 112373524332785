import React, { useEffect, useRef } from 'react';

import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';

import mobileIcons from '../../../../../assets/images/services/ios_android_icons.png';
import classes from './styles.module.scss';

export default function Service({
  title,
  icon,
  setActiveService,
  isActive,
  activeIcon,
  serviceInfo,
}) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const serviceRef = useRef();

  useEffect(() => {
    serviceRef.current.style.setProperty(
      '--rotation',
      `${Math.floor(Math.random() * 361)}deg`
    );
  }, []);

  return (
    <li
      ref={serviceRef}
      className={classNames(classes.Service, {
        [classes.active]: isActive,
      })}
    >
      <div
        className={classes.button}
        onClick={() =>
          setActiveService((prevState) => {
            if (prevState === title && isTabletOrMobile) {
              return '';
            }
            return title;
          })
        }
      >
        <img src={isActive ? activeIcon : icon} alt="title" />
        <p>{title}</p>
      </div>
      {isTabletOrMobile && isActive && (
        <div className={classes.info}>
          {title === 'Mobile App Development' && (
            <p className={classes.iconsContainer}>
              <img src={mobileIcons} alt="iOS and Android" />
            </p>
          )}
          {serviceInfo}
        </div>
      )}
    </li>
  );
}
