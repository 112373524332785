/* eslint-disable no-unused-vars */
import React, { useRef } from 'react';

import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import LinkButton from '../../LinkButton';

import useElementVisible from '../../../../hooks/useElementVisible';
import classes from './styles.module.scss';

export default function Hero() {
  const navigate = useNavigate();

  const isLowHeight = useMediaQuery({ query: '(max-height: 450px)' });

  const sectionRef = useRef();
  useElementVisible(sectionRef, () => navigate('/', { replace: true }));

  return (
    <section className={classes.Hero} id="start" ref={sectionRef}>
      <h1 className={classes.tabletHeading}>Achieve Your Digital Goals</h1>
      <div className={classes.container}>
        <div className={classes.foreground} />
        <div
          className={classes.content}
          // style={{ backgroundImage: isLowHeight ? 'none' : '' }}
        >
          {isLowHeight ? (
            <div className={classes.innerContainer}>
              <h1>Achieve Your Digital Goals</h1>
              <p>
                We are a group of innovative and skilled individuals devoted to
                making your ideas come to life.
              </p>
            </div>
          ) : (
            <>
              <h1>Achieve Your Digital Goals</h1>
              <p>
                We are a group of innovative and skilled individuals devoted to
                making your ideas come to life.
              </p>
            </>
          )}
          <div className={classes.buttonContainer}>
            <LinkButton />
          </div>
        </div>
      </div>
    </section>
  );
}
