import React from 'react';

import Background from './Background';

import classes from './styles.module.scss';

export default function SectionHeader({
  title,
  text,
  style,
  textStyle,
  noBackground,
  leftBackgroundRotation,
  rightBackgroundRotation,
  rightBackgroundTop,
}) {
  return (
    <header className={classes.SectionHeader} style={style}>
      <h2>{title}</h2>
      <p style={textStyle}>{text}</p>
      {!noBackground && (
        <>
          <Background rotation={leftBackgroundRotation} />
          <Background
            rotation={rightBackgroundRotation}
            rightTop={rightBackgroundTop}
            right
          />
        </>
      )}
    </header>
  );
}
