/* eslint-disable prefer-destructuring */
import { useEffect, useMemo } from 'react';

function useElementVisible(elementRef, handler, threshold) {
  const observer = useMemo(
    () =>
      new window.IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            handler();
          }
        },
        {
          root: null,
          threshold: threshold || 0.4,
        }
      ),
    []
  );

  useEffect(() => {
    observer.observe(elementRef.current);
  }, [elementRef, observer]);
}

export default useElementVisible;
