import React, { useRef } from 'react';

import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import Client from './Client';
import SectionHeader from '../../SectionHeader';

import useElementVisible from '../../../../hooks/useElementVisible';
import classes from './styles.module.scss';
import jaffaIcon from '../../../../assets/images/clients/icons/Jaffa_logo.png';
import jaffaImage from '../../../../assets/images/clients/jaffa.png';
import twentyOneLearningIcon from '../../../../assets/images/clients/icons/twentyone_logo.png';
import twentyOneLearningImage from '../../../../assets/images/clients/twentyonelearning.png';
import songPopIcon from '../../../../assets/images/clients/icons/songpop_logo.png';
import songPopImage from '../../../../assets/images/clients/songpop.png';
import built4TennisIcon from '../../../../assets/images/clients/icons/built4tennis_logo.png';
import built4TennisImage from '../../../../assets/images/clients/built4tennis.jpg';
import joolzIcon from '../../../../assets/images/clients/icons/joolz_logo.jpg';
import joolzImage from '../../../../assets/images/clients/joolz.png';
import babymouIcon from '../../../../assets/images/clients/icons/babymou_logo.png';
import babymouImage from '../../../../assets/images/clients/babymou.jpg';

const clients = [
  {
    title: 'TwentyOneLearning',
    text: (
      <>
        <p>
          Education app that empowers educators to create interactive content
          you can see, hear, touch and collaborate with in real-time thereby
          optimizing learning.
        </p>
        <p>We helped to bring education to 21st century.</p>
      </>
    ),
    icon: twentyOneLearningIcon,
    image: twentyOneLearningImage,
  },
  {
    title: 'SongPop',
    text: (
      <>
        <p>
          We collaborated with FreshPlanet to create the world&apos;s most
          popular music trivia game! Featuring real time multiplayer and
          thousands of real music clips the game is played by millions of
          players worldwide!
        </p>
        <p>
          This game is available on iOS, Android, MacOS, Windows and Amazon
          platforms.
        </p>
      </>
    ),
    icon: songPopIcon,
    image: songPopImage,
  },
  {
    title: 'Built4Tennis',
    text: (
      <>
        <p>Built 4 Tennis is tennis fitness and training app.</p>
        <p>
          It was created around the training philosophies of Jez Green (Trainer
          of Alexander Zverev, Andy Murray + Tomas Berdych). Choose from over 10
          chapters of over 50 narrated videos that teach you how to build the
          body of a tennis athlete.
        </p>
      </>
    ),
    icon: built4TennisIcon,
    image: built4TennisImage,
  },
  {
    title: 'Joolz',
    text: (
      <>
        <p>Discover your perfect jewellery match with Joolz app!</p>
        <p>
          The top marketplace for the world&apos;s top brands and designs. Joolz
          is your one stop shop for your favorite jewellery online.
        </p>
      </>
    ),
    icon: joolzIcon,
    image: joolzImage,
  },
  {
    title: 'Babymou',
    text: (
      <>
        <p>Meet Your Greek.</p>
        <p>
          The first app on the iTunes store to exclusively match you with Greek
          singles from around the world.
        </p>
      </>
    ),
    icon: babymouIcon,
    image: babymouImage,
  },
  {
    title: 'Jaffa Cakes',
    text: (
      <p>
        We helped develop games for Jaffa Cakes marketing campaign spanning
        across 5 different countries.
      </p>
    ),
    icon: jaffaIcon,
    image: jaffaImage,
  },
];

export default function Clients() {
  const navigate = useNavigate();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const sectionRef = useRef();
  useElementVisible(
    sectionRef,
    () => navigate('#clients', { replace: true }),
    0.1
  );

  return (
    <section className={classes.Clients} id="clients" ref={sectionRef}>
      <SectionHeader
        leftBackgroundRotation={28}
        rightBackgroundRotation={230}
        rightBackgroundTop={-40}
        title="CLIENTS"
        text="We’ve helped to build some incredible products for our clients"
        style={{ marginBottom: isTabletOrMobile ? 75 : 225 }}
      />
      <ul className={classes.clientsList}>
        {clients.map((client) => (
          <Client
            key={client.title}
            id={client.title}
            title={client.title}
            text={client.text}
            icon={client.icon}
            image={client.image}
          />
        ))}
      </ul>
    </section>
  );
}
