import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function Shape({ shape }) {
  return (
    <div
      style={{
        top: shape.top,
        left: shape.left,
        right: shape.right,
        bottom: shape.bottom,
        transform: `scale(${shape.scale}) rotate(${Math.floor(
          Math.random() * 361
        )}deg)`,
      }}
      className={classNames(classes.Shape, {
        [classes.circle]: shape.type === 'circle',
        [classes.triangle]: shape.type === 'triangle',
      })}
    />
  );
}
