import React from 'react';

import classes from './styles.module.scss';

export default function TechList({ tech }) {
  return (
    <div className={classes.TechList}>
      <header className={classes.header}>
        <img className={classes.icon} src={tech.icon} alt={tech.title} />
        <h3 className={classes.title}>{tech.title}</h3>
      </header>
      <ul className={classes.techsList}>
        {tech.items.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    </div>
  );
}
