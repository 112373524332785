import React from 'react';

import classes from './styles.module.scss';

export default function Client({ title, text, icon, image }) {
  return (
    <li className={classes.Client}>
      <img src={image} alt={title} className={classes.image} />
      <div className={classes.header}>
        <img src={icon} alt={title} className={classes.icon} /> <h3>{title}</h3>
      </div>
      <div className={classes.text}>{text}</div>
    </li>
  );
}
