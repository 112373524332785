import React from 'react';

import classes from './styles.module.scss';

export default function Footer() {
  return (
    <footer className={classes.Footer}>
      <p className={classes.copyright}>
        © Deloki Code Solutions {new Date().getFullYear()}
      </p>
    </footer>
  );
}
